import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Avatar } from "antd";
const { Text, Link } = Typography;

const LotTransferOutCard = (props) => {
    const { data } = props;
    return (
        <div className="hp-p-36 hp-border-radius-xxl hp-card-1 hp-overflow-hidden">
            <div
                className="hp-position-absolute-top-left hp-w-100 hp-h-100 hp-nft-dashboard-own-nft-bg"
                style={{ backgroundSize: "cover", backgroundPosition: "center", backgroundColor: "white" }}
            ></div>

            <Row align="middle" >
                <Col
                    span={6}>
                    <Avatar size={45} icon={props.icon} className="hp-bg-primary-1 hp-text-color-black-0" />
                </Col>

                <Col >
                    <Text className="h4 hp-d-block hp-text-color-white-0 hp-mb-6" strong>
                        {props.title}
                    </Text>
                    <Text className="h2 hp-text-color-white-0" strong>
                        {data ? data : 0}
                    </Text>
                </Col>
            </Row>
        </div>
    );
}

export default LotTransferOutCard;
