import axios from "axios";

import { v4 as uuidv4 } from "uuid";
import { browserName } from "react-device-detect";

const BaseURL = process.env.REACT_APP_BASE_URL; //Base URL

const TOKEN_PREFIX = "Bearer  ";
const uuid = uuidv4();

const axiosInstance = axios.create({
  baseURL: BaseURL,
  timeout: 80000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? TOKEN_PREFIX + localStorage.getItem("access_token")
      : null,

    // "Content-Type": "application/json",
    // accept: "application/json",
  },
});
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

function removeToken() {
  localStorage.clear();
  window.location.href = "/login/";
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken && refreshToken !== "undefined") {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return new Promise(function (resolve, reject) {
            axiosInstance
              .post(BaseURL + "/users/token/refresh/", {
                refresh: refreshToken,
                device_uuid: uuid,
                device_name: browserName,
                device_type: 3, // web
              })
              .then((response) => {
                localStorage.setItem("access_token", response.data.access);

                axiosInstance.defaults.headers["Authorization"] =
                  TOKEN_PREFIX + response.data.access;
                originalRequest.headers["Authorization"] =
                  TOKEN_PREFIX + response.data.access;
                processQueue(null, response.data.access);
                resolve(axiosInstance(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                // store.dispatch(showMessage({ message: "Expired Token" }));

                // window.location = "/login/";
                removeToken();

                reject(err);
              })
              .then(() => {
                isRefreshing = false;
              });
          });
        } else {
          // window.location.href = "/login/";
          removeToken();
        }
      } else {
        // window.location.href = "/login/";
        removeToken();
      }
    } else if (err.response.status === 403) {
      window.location.href = "/pages/error-page-403";
    } else if (err.response.status === 500) {
      window.location.href = "/pages/error-page-500";
    } else if (err.response.status === 502) {
      window.location.href = "/pages/error-page-502";
    } else if (err.response.status === 503) {
      window.location.href = "/pages/error-page-503";
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
export {
  BaseURL,
  // IOBaseURL,
  axiosInstance,
  TOKEN_PREFIX,
};
