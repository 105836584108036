import { createSlice } from "@reduxjs/toolkit";
import { getInternalShipmentList, getInternalShipmentByID } from "./internalShipmentAction";

const internalShipmentSlice = createSlice({
    name: "internalshipment",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        internalShipmentlist: [],
        internalShipmentParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetInternalDeliveryOrderParams: (state, action) => {
            return {
                ...state,
                internalShipmentParams: {
                    ...state.internalShipmentParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInternalShipmentList.pending, (state, action) => {
                state.status = "getInternalShipmentList loading";
                state.loading = true;
            })
            .addCase(getInternalShipmentList.fulfilled, (state, action) => {
                state.status = "getInternalShipmentList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.internalShipmentParams?.page_size
                );

                state.internalShipmentlist = response.results;
                state.listCount = response.count;
                state.internalShipmentParams = {
                    ...state.internalShipmentParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getInternalShipmentList.rejected, (state, action) => {
                state.status = "getInternalShipmentList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getInternalShipmentByID.pending, (state, action) => {
                state.status = "getInternalShipmentByID loading";
                state.loading = true;
            })
            .addCase(getInternalShipmentByID.fulfilled, (state, action) => {
                state.status = "getInternalShipmentByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getInternalShipmentByID.rejected, (state, action) => {
                state.status = "getInternalShipmentByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
           
    }

});
export const {
    InputChangeValue,
    SetInternalDeliveryOrderParams,
    isModelVisible
} = internalShipmentSlice.actions;

export default internalShipmentSlice.reducer;