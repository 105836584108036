import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../helpers/Helper";
import { Modal } from "antd";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";

export const getVehicleInwardCount = createAsyncThunk(
    "/getVehicleInwardCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/towards/vehicle_inward_count/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getVehicleUnloadingCount = createAsyncThunk(
    "/getVehicleUnloadingCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/towards/vehicle_unloading_count/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getLotTransferOutCount = createAsyncThunk(
    "/getLotTransferOutCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/transfers/lot_transfer_out_count/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getLotTransferInCount = createAsyncThunk(
    "/getLotTransferInCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/transfers/lot_transfer_in_count/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getStockAdjustmentPlusCount = createAsyncThunk(
    "/getStockAdjustmentPlusCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/adjustments/stock_adjustment_plus_count/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getStockAdjustmentMinusCount = createAsyncThunk(
    "/getStockAdjustmentMinusCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/adjustments/stock_adjustment_minus_count/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getProductionInCount = createAsyncThunk(
    "/getProductionInCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/production/production_in_count/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);

export const getProductionOutCount = createAsyncThunk(
    "/getProductionOutCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/production/production_out_count/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getVehicleOutwardCount = createAsyncThunk(
    "/getVehicleOutwardCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/production/vehicle_outward_count/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getProductionInMonthlyReport = createAsyncThunk(
    "/getProductionInMonthlyReport",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/production/production_in/monthly_report/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getProductionOutMonthlyReport = createAsyncThunk(
    "/getProductionOutMonthlyReport",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/production/production_out/monthly_report/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getOtherJattuActivityCount = createAsyncThunk(
    "/getOtherJattuActivityCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/adjustments/other_jattu_activities_count/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getInternalTransferOutCount = createAsyncThunk(
    "/getInternalTransferOutCount",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/transfers/internal_transfer_out_count/", params);
            if (response) {
                return { response, params };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);