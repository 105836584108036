import React, { useState } from "react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { switchable } from "./code.js";

import {
  Card,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Button,
} from "antd";
import { RiCodeSSlashLine } from "react-icons/ri";

const { Option } = Select;

export default function SwitchablePicker(props) {
  const { onChange, onBlur, PickerType, PickerTypeValue, value } = props;

  function PickerWithType({ type, value, onChange }) {
    if (type === "date") return <DatePicker value={value}  {...props} onChange={onChange} />;
    return <DatePicker value={value}  {...props} picker={type} onChange={onChange} />;
  }

  function handleChange(value) {
    PickerType(value)
  }

  return (
    <Space size={24}>
      <Select value={PickerTypeValue} onChange={(e) => handleChange(e)}>
        <Option value="date">Date</Option>
        <Option value="week">Week</Option>
        <Option value="month">Month</Option>
        <Option value="year">Year</Option>
      </Select>
      <PickerWithType type={PickerTypeValue}
        value={value}
        onChange={(value) => onChange(value)} />
    </Space>
  );
}



