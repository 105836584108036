import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Avatar } from "antd";
const { Text, Link } = Typography;

const OtherJattuActivityCard = (props) => {
    const { data } = props;
    return (
        <div>
            <Card sx={{
                width: {
                    xs: "100%",
                    sm: "450px", 
                    md: "540px",
                },
                height: "150px",
                padding: "10px",
                backgroundColor: "lightgray",
            }}>
                <Row align="middle" >
                    <Col
                        span={6}>
                        <Avatar size={45} icon={props.icon} className="hp-bg-primary-1 hp-text-color-black-0" />
                    </Col>

                    <Col >
                        <Text className="h4 hp-d-block hp-text-color-white-0 hp-mb-6" strong>
                            {props.title}
                        </Text>
                        <Text className="h2 hp-text-color-white-0" strong>
                            {data ? data : 0}
                        </Text>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default OtherJattuActivityCard;
