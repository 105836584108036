import React, { useState, useRef } from "react";
import { Card, Row, Col, Input, Form, Tabs, Spin } from "antd";
import PageTitle from "../../layout/components/content/page-title";
import { isMobile } from "react-device-detect";
import moment, { min } from "moment";
import {
  PicLeftOutlined,
  UserSwitchOutlined,
  PicRightOutlined,
  ContainerOutlined,
  TruckOutlined,
  ExportOutlined,
  LeftSquareOutlined,
  ImportOutlined,
  RightSquareOutlined, InteractionOutlined
} from "@ant-design/icons";
import { useEffect } from "react";
import ProductionInCard from "./productionInCard";
import ProductionOutCard from "./productionOutCard";
import VehicleInwardCard from "./vehicleInwardCard";
import {
  getVehicleInwardCount, getProductionInCount, getProductionOutCount, getLotTransferInCount, getLotTransferOutCount, getVehicleUnloadingCount,
  getStockAdjustmentPlusCount, getStockAdjustmentMinusCount, getVehicleOutwardCount, getProductionInMonthlyReport, getProductionOutMonthlyReport, getOtherJattuActivityCount,
  getInternalTransferOutCount
} from "../../store/dashboard/dashboardAction";
import { FilterInputChangeValue, clearData } from "../../store/dashboard/dashboardSlice";
import VehicleUnloadCard from "./vehicleUnloadCard";
import LotTransferOutCard from "./lotTransferOutCard";
import LotTransferInCard from "./lotTransferInCard";
import OtherJattuActivityCard from "./otherJattuActivityCard";
import InternalTransferOutCard from "./internalTransferOut";
import StockAdjustmentPlusCard from "./stockPlusCard";
import StockAdjustmentMinusCard from "./stockMinusCard";
import VehicleOutwardCard from "./vehicleOutwardCard";
import AnalyticsCard from "./analyticsCard";
import { Selector, Dispatch } from "../../../src/store";
import { useDispatch } from "react-redux";
import SwitchablePicker from "../../view/components/data-entry/datepicker/switchable";

export default function Home(props) {
  const { Search } = Input;
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("1")
  const vehicleInwardCount = Selector("dashboard", "vehicleInwardCount");
  const vehicleUnloadingData = Selector("dashboard", "vehicleUnloadingData");
  const stockMinusCount = Selector("dashboard", "stockMinusCount");
  const stockPlusCount = Selector("dashboard", "stockPlusCount");
  const lotTransferOutCount = Selector("dashboard", "lotTransferOutCount");
  const otherJattuCount = Selector("dashboard", "otherJattuCount");
  const internaltransferCount = Selector("dashboard", "internaltransferCount");
  const lotTransferInCount = Selector("dashboard", "lotTransferInCount");
  const productionInData = Selector("dashboard", "productionInData");
  const productionOutData = Selector("dashboard", "productionOutData");
  const vehicleOutwardCount = Selector("dashboard", "vehicleOutwardCount");
  const productionInMonthlyReport = Selector("dashboard", "productionInMonthlyReport");
  const productionOutMonthlyReport = Selector("dashboard", "productionOutMonthlyReport");
  const dashboardParams = Selector("dashboard", "dashboardParams");
  const isInitialRender = useRef(true);
  const previousParams = useRef(null);

  const onFinish = () => {
    dashboardAPIs();
  };

  function onSelectTab(key) {
    setActiveTab(key)
    if (key == 1) {
      dispatch(getProductionInMonthlyReport({
        ...dashboardParams,
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,
      }));
    } else {
      dispatch(getProductionOutMonthlyReport({
        ...dashboardParams,
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,
      }));
    }
  }
  function dashboardAPIs() {
    dispatch(getVehicleInwardCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getVehicleUnloadingCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getVehicleUnloadingCount({
      worktype: 1, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getVehicleUnloadingCount({
      worktype: 2, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getVehicleUnloadingCount({
      worktype: 3, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getLotTransferInCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getStockAdjustmentPlusCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getStockAdjustmentMinusCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getLotTransferOutCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionInCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionInCount({
      productiontype: 1, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionInCount({
      productiontype: 2, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionInCount({
      productiontype: 3, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionOutCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionOutCount({
      productiontype: 1, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionOutCount({
      productiontype: 2, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionOutCount({
      productiontype: 3, start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getVehicleOutwardCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getInternalTransferOutCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getOtherJattuActivityCount({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
    dispatch(getProductionInMonthlyReport({
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    }));
  }
  useEffect(() => {
    dispatch(clearData());
    if (isInitialRender.current) {
      const { start_date, end_date } = getDefaultDates();
      dashboardAPIs();
      previousParams.current = { start_date, end_date };
      isInitialRender.current = false;
    }
  }, [dispatch]);

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;

    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck =
          current &&
          current < moment(startDate + " 00:00:01", "YYYY-MM-DD HH:mm:ss");
      }
      if (endDate) {
        endCheck =
          current &&
          current >
          moment(
            moment(endDate).format("YYYY-MM-DD") + " 23:59:59",
            "YYYY-MM-DD HH:mm:ss"
          );
      }

      return (startDate && startCheck) || (endDate && endCheck);
    };
  }
  const getDefaultDates = () => {
    const today = new Date();
    const yesterday = new Date();
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    return {
      start_date: formatDate(today),
      end_date: formatDate(yesterday),
    };
  };

  useEffect(() => {
    if (isInitialRender.current) return;

    if (!dashboardParams?.start_date || !dashboardParams?.end_date) return;
    if (
      dashboardParams.start_date === dashboardParams.end_date
    ) {
      return;
    }
    dashboardAPIs();
  }, [dashboardParams.start_date, dashboardParams.end_date, dispatch]);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     start_date: dashboardParams?.start_date,
  //   })
  // }, [dashboardParams])

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10" >
          <Col
            span={22}
            style={{
              display: "flex",
              flexDirection: "row", // Change to row to align items horizontally
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Col>
              <PageTitle pageTitle="Dashboard" goBack={false} />
            </Col>

            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Col
                span={20}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Form.Item
                  // label="Select Date"
                  name="start_date"
                  className="hp-mb-0"
                >
                  <SwitchablePicker
                    id="start_date"
                    name="start_date"
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                    PickerType={(e) => {
                      dispatch(
                        FilterInputChangeValue({
                          key: "dateType",
                          value: e,
                        })
                      );
                    }}
                    PickerTypeValue={dashboardParams?.dateType}
                    disabledDate={disableDateRanges({
                      endDate: new Date(),
                    })}
                    value={dashboardParams?.start_date}
                    onChange={(date) => {
                      switch (dashboardParams?.dateType) {
                        case "week":
                          const startOfWeek = date?.clone()
                            .startOf("isoWeek")
                            .subtract(1, "day"); // Monday
                          const endOfWeek = date?.clone()
                            .endOf("isoWeek")
                            .subtract(1, "day"); // Saturday
                          dispatch(
                            FilterInputChangeValue({
                              key: "start_date",
                              value: startOfWeek,
                            })
                          );
                          dispatch(
                            FilterInputChangeValue({
                              key: "end_date",
                              value: endOfWeek,
                            })
                          );
                          break;

                        case "month":
                          const startOfMonth = date?.clone().startOf("month"); // Add one day to the start of the month
                          const endOfMonth = date?.clone().endOf("month"); // Keep the end of the month as is
                          dispatch(
                            FilterInputChangeValue({
                              key: "start_date",
                              value: startOfMonth,
                            })
                          );
                          dispatch(
                            FilterInputChangeValue({
                              key: "end_date",
                              value: endOfMonth,
                            })
                          );
                          break;

                        case "year":
                          const startOfYear = date?.clone().startOf("year"); // Add one day to the start of the year
                          const endOfYear = date?.clone().endOf("year"); // Keep the end of the year as is
                          dispatch(
                            FilterInputChangeValue({
                              key: "start_date",
                              value: startOfYear,
                            })
                          );
                          dispatch(
                            FilterInputChangeValue({
                              key: "end_date",
                              value: endOfYear,
                            })
                          );
                          break;

                        default:
                          // For default date picker
                          const endOfdate = date?.clone().add(1, "day");
                          dispatch(
                            FilterInputChangeValue({
                              key: "start_date",
                              value: date == null ? "" : date,
                            })
                          );
                          dispatch(
                            FilterInputChangeValue({
                              key: "end_date",
                              value: endOfdate,
                            })
                          );
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Row gutter={[12, 12]}>
              <Col span={24} xs={24} sm={24} md={24} xl={18}>
                <Row gutter={[12, 12]}>

                  <Col span={16} xs={24} sm={12} md={8} xl={8}>
                    <VehicleInwardCard
                      title=" Vehicle Inward"
                      data={vehicleInwardCount}
                      icon={<ContainerOutlined />}
                    />
                  </Col>
                  <Col span={16} xs={24} sm={12} md={8} xl={8}>
                    <VehicleUnloadCard
                      title=" Vehicle Unloading"
                      data={vehicleUnloadingData}
                      icon={<TruckOutlined />}
                    />
                  </Col>
                  <Col span={16} xs={24} sm={12} md={8} xl={8}>
                    <LotTransferOutCard
                      title=" Lot Transfer Out"
                      data={lotTransferOutCount}
                      icon={<ExportOutlined />}
                    />
                  </Col>
                  <Col span={16} xs={24} sm={12} md={8} xl={8}>
                    <ProductionInCard
                      title=" Production In"
                      data={productionInData}
                      icon={<RightSquareOutlined />}
                    />
                  </Col>
                  <Col span={16} xs={24} sm={12} md={8} xl={8}>
                    <ProductionOutCard
                      title=" Production Out"
                      data={productionOutData}
                      icon={<LeftSquareOutlined />}
                    />
                  </Col>
                  <Col span={16} xs={24} sm={12} md={8} xl={8}>
                    <StockAdjustmentPlusCard
                      title=" Stock Adjustment Plus"
                      data={stockPlusCount}
                      icon={<PicRightOutlined />}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24} xs={24} sm={24} md={24} xl={6}>
                <Row gutter={[12, 12]}>
                  <Col span={24} xs={24} sm={24} md={24} xl={24} style={{ marginLeft: "10px" }}>
                    <LotTransferInCard
                      title=" Lot Transfer In"
                      data={lotTransferInCount}
                      icon={<ImportOutlined />}
                    />
                  </Col>

                  <Col span={24} xs={24} sm={24} md={24} xl={24} style={{ marginLeft: "10px" }}>
                    <StockAdjustmentMinusCard
                      title=" Stock Adjustment Minus"
                      data={stockMinusCount}
                      icon={<PicLeftOutlined />}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="hp-mt-10">
          <Col span={16} xs={24} sm={12} md={8} xl={8} style={{ marginRight: "0px" }}>
            <OtherJattuActivityCard
              title=" Other Jattu Activity"
              data={otherJattuCount}
              icon={<UserSwitchOutlined />}
            />
          </Col>
          <Col span={16} xs={24} sm={12} md={8} xl={8}>
            <InternalTransferOutCard
              title=" Internal Transfer Out"
              data={internaltransferCount}
              icon={<InteractionOutlined />}
            />
          </Col>
          <Col span={16} xs={24} sm={12} md={8} xl={8}>
            <VehicleOutwardCard
              title=" Vehicle Outward"
              data={vehicleOutwardCount}
              icon={<TruckOutlined />}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]} className="hp-mt-10">
          <Col span={24} xs={24} sm={24} md={24} xl={24}>
            <Card bodyStyle={{ padding: "10px" }}>
              <Tabs defaultActiveKey="1" onChange={onSelectTab}>
                <TabPane tab="Production In" key="1">
                  <AnalyticsCard
                    title="Production In Monthly Report"
                    data={productionInMonthlyReport}
                  />
                </TabPane>

                <TabPane tab="Production Out" key="2">
                  <AnalyticsCard
                    title="Production Out Monthly Report"
                    data={productionOutMonthlyReport}
                  />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row >
  );
}