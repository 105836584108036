import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Avatar } from "antd";
import { RiBarChart2Line } from "react-icons/ri";
const { Text, Link } = Typography;

const VehicleOutwardCard = (props) => {

    const { data } = props;
    return (
        <div>
            <Card sx={{
                 width: {
                    xs: "100%",
                    sm: "450px",
                    md: "530px", 
                },
                height: {
                    xs: '120px',
                    sm: '150px',
                    md: '150px',
                    xl: '200px'
                },
                padding: '10px',
                marginLeft: '20px'
            }}>
                <Row align="middle" >
                    <Col
                        span={6}>
                        <Avatar size={45} icon={props.icon} className="hp-bg-primary-1 hp-text-color-black-0" />
                    </Col>

                    <Col >
                        <Text className="h4 hp-d-block hp-text-color-white-0 hp-mb-6" strong>
                            {props.title}
                        </Text>
                        <Text className="h2 hp-text-color-white-0" strong>
                            {data ? data : 0}
                        </Text>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default VehicleOutwardCard;
