import { createSlice } from "@reduxjs/toolkit";
import {
  getVehicleInwardCount, getVehicleUnloadingCount, getProductionOutCount, getProductionInCount, getLotTransferInCount, getLotTransferOutCount,
  getStockAdjustmentPlusCount, getStockAdjustmentMinusCount, getVehicleOutwardCount, getProductionOutMonthlyReport, getProductionInMonthlyReport,
  getInternalTransferOutCount, getOtherJattuActivityCount
} from "./dashboardAction";
import moment from "moment";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    error: "",
    status: "",
    loading: false,
    vehicleInwardCount: 0,
    vehicleOutwardCount: 0,
    vehicleUnloadingCount: 0,
    vehicleUnloadingData: {},
    SaleCount: 0,
    JobWorkGivenCount: 0,
    jobWorkTakenCount: 0,
    MillingCount: 0,
    JobWorkCount: 0,
    ReprocessingCount: 0,
    productionInData: {},
    lotTransferOutCount: 0,
    lotTransferInCount: 0,
    productionInCount: 0,
    productionOutCount: 0,
    otherJattuCount: 0,
    internaltransferCount: 0,
    productionOutData: {},
    stockPlusCount: 0,
    stockMinusCount: 0,
    productionInMonthlyReport: [],
    productionOutMonthlyReport: [],
    dashboardParams: {
      dateType: "date",
      start_date: moment(new Date()).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    },
  },
  reducers: {
    clearData: (state, action) => {
      return {
        ...state,
        loading: false,
        vehicleInwardCount: 0,
        vehicleOutwardCount: 0,
        vehicleUnloadingCount: 0,
        vehicleUnloadingData: {},
        SaleCount: 0,
        JobWorkGivenCount: 0,
        jobWorkTakenCount: 0,
        MillingCount: 0,
        JobWorkCount: 0,
        ReprocessingCount: 0,
        productionInData: {},
        lotTransferOutCount: 0,
        lotTransferInCount: 0,
        productionInCount: 0,
        productionOutCount: 0,
        otherJattuCount: 0,
        internaltransferCount: 0,
        productionOutData: {},
        stockPlusCount: 0,
        stockMinusCount: 0,
        productionInMonthlyReport: [],
        productionOutMonthlyReport: [],
        dashboardParams: {
          dateType: "date",
          start_date: moment(new Date()).format("YYYY-MM-DD"),
          end_date: moment(new Date()).format("YYYY-MM-DD"),
        },
      };
    },
    FilterInputChangeValue: (state, action) => {

      switch (action.payload.key) {
        case "dateType":
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              dateType: action.payload.value
            },
          };

        case "start_date":
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              start_date: moment(action.payload.value).format("YYYY-MM-DD")
            },
          };

        case "end_date":
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              end_date: moment(action.payload.value).format("YYYY-MM-DD")
            },
          };
        default:
          return {
            ...state,
            dashboardParams: {
              ...state.dashboardParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }

    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getVehicleInwardCount.pending, (state, action) => {
        state.status = "getVehicleInwardCount loading";
        state.loading = true;
      })
      .addCase(getVehicleInwardCount.fulfilled, (state, action) => {
        state.status = "getVehicleInwardCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        state.vehicleInwardCount = response.count;
      })
      .addCase(getVehicleInwardCount.rejected, (state, action) => {
        state.status = "getVehicleInwardCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getVehicleUnloadingCount.pending, (state, action) => {
        state.status = "getVehicleUnloadingCount loading";
        state.loading = true;
      })
      .addCase(getVehicleUnloadingCount.fulfilled, (state, action) => {
        state.status = "getVehicleUnloadingCount succeeded";
        state.loading = false;
        var data = action.payload;
        var response = action.payload.response;
        switch (data.params?.worktype) {
          case 1:
            state.SaleCount = response.count;
            break;
          case 2:
            state.JobWorkGivenCount = response.count;
            break;
          case 3:
            state.jobWorkTakenCount = response.count;
            break;
          default:
            state.vehicleUnloadingCount = response.count;
            break;
        }
        state.vehicleUnloadingData = {
          salecount: state.SaleCount,
          jobgivencount: state.JobWorkGivenCount,
          jobtakencount: state.jobWorkTakenCount,
          unloadingcount: state.vehicleUnloadingCount,
        }
      })
      .addCase(getVehicleUnloadingCount.rejected, (state, action) => {
        state.status = "getVehicleUnloadingCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLotTransferOutCount.pending, (state, action) => {
        state.status = "getLotTransferOutCount loading";
        state.loading = true;
      })
      .addCase(getLotTransferOutCount.fulfilled, (state, action) => {
        state.status = "getLotTransferOutCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        state.lotTransferOutCount = response.count;
      })
      .addCase(getLotTransferOutCount.rejected, (state, action) => {
        state.status = "getLotTransferOutCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLotTransferInCount.pending, (state, action) => {
        state.status = "getLotTransferOutCount loading";
        state.loading = true;
      })
      .addCase(getLotTransferInCount.fulfilled, (state, action) => {
        state.status = "getLotTransferOutCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        state.lotTransferInCount = response.count;
      })
      .addCase(getLotTransferInCount.rejected, (state, action) => {
        state.status = "getLotTransferOutCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductionInCount.pending, (state, action) => {
        state.status = "getProductionInCount loading";
        state.loading = true;
      })
      .addCase(getProductionInCount.fulfilled, (state, action) => {
        state.status = "getProductionInCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        var data = action.payload;
        var response = action.payload.response;
        switch (data.params?.productiontype) {
          case 1:
            state.MillingCount = response.count;
            break;
          case 2:
            state.JobWorkCount = response.count;
            break;
          case 3:
            state.ReprocessingCount = response.count;
            break;
          default:
            state.productionInCount = response.count;
            break;
        }
        state.productionInData = {
          millingcount: state.MillingCount,
          jobworkcount: state.JobWorkCount,
          reprocessingcount: state.ReprocessingCount,
          production_incount: state.productionInCount,
        }
      })
      .addCase(getProductionInCount.rejected, (state, action) => {
        state.status = "getProductionInCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductionOutCount.pending, (state, action) => {
        state.status = "getProductionOutCount loading";
        state.loading = true;
      })
      .addCase(getProductionOutCount.fulfilled, (state, action) => {
        state.status = "getProductionOutCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        var data = action.payload;
        var response = action.payload.response;
        switch (data.params?.productiontype) {
          case 1:
            state.MillingCount = response.count;
            break;
          case 2:
            state.JobWorkCount = response.count;
            break;
          case 3:
            state.ReprocessingCount = response.count;
            break;
          default:
            state.productionOutCount = response.count;
            break;
        }
        state.productionOutData = {
          millingcount: state.MillingCount,
          jobworkcount: state.JobWorkCount,
          reprocessingcount: state.ReprocessingCount,
          production_outcount: state.productionOutCount,
        }
      })
      .addCase(getProductionOutCount.rejected, (state, action) => {
        state.status = "getProductionOutCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStockAdjustmentPlusCount.pending, (state, action) => {
        state.status = "getStockAdjustmentPlusCount loading";
        state.loading = true;
      })
      .addCase(getStockAdjustmentPlusCount.fulfilled, (state, action) => {
        state.status = "getStockAdjustmentPlusCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        state.stockPlusCount = response.count;
      })
      .addCase(getStockAdjustmentPlusCount.rejected, (state, action) => {
        state.status = "getStockAdjustmentPlusCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStockAdjustmentMinusCount.pending, (state, action) => {
        state.status = "getStockAdjustmentMinusCount loading";
        state.loading = true;
      })
      .addCase(getStockAdjustmentMinusCount.fulfilled, (state, action) => {
        state.status = "getStockAdjustmentMinusCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        state.stockMinusCount = response.count;
      })
      .addCase(getStockAdjustmentMinusCount.rejected, (state, action) => {
        state.status = "getStockAdjustmentMinusCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getVehicleOutwardCount.pending, (state, action) => {
        state.status = "getVehicleOutwardCount loading";
        state.loading = true;
      })
      .addCase(getVehicleOutwardCount.fulfilled, (state, action) => {
        state.status = "getVehicleOutwardCount succeeded";
        state.loading = false;
        var response = action.payload.response;
        state.vehicleOutwardCount = response.count;
      })
      .addCase(getVehicleOutwardCount.rejected, (state, action) => {
        state.status = "getVehicleOutwardCount failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductionInMonthlyReport.pending, (state, action) => {
        state.status = "getProductionInMonthlyReport loading";
        state.loading = true;
      })
      .addCase(getProductionInMonthlyReport.fulfilled, (state, action) => {
        state.status = "getProductionInMonthlyReport successed";
        state.loading = false;
        state.productionInMonthlyReport = action.payload.response
      })
      .addCase(getProductionInMonthlyReport.rejected, (state, action) => {
        state.status = "getProductionInMonthlyReport rejected";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getProductionOutMonthlyReport.pending, (state, action) => {
        state.status = "getProductionOutMonthlyReport loading";
        state.loading = true;
      })
      .addCase(getProductionOutMonthlyReport.fulfilled, (state, action) => {
        state.status = "getProductionOutMonthlyReport successed";
        state.loading = false;
        state.productionOutMonthlyReport = action.payload.response;
      })
      .addCase(getProductionOutMonthlyReport.rejected, (state, action) => {
        state.status = "getProductionOutMonthlyReport rejected";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getOtherJattuActivityCount.pending, (state, action) => {
        state.status = "getOtherJattuActivityCount loading";
        state.loading = true;
      })
      .addCase(getOtherJattuActivityCount.fulfilled, (state, action) => {
        state.status = "getOtherJattuActivityCount successed";
        state.loading = false;
        var response = action.payload.response;
        state.otherJattuCount = response.count;
      })
      .addCase(getOtherJattuActivityCount.rejected, (state, action) => {
        state.status = "getOtherJattuActivityCount rejected";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getInternalTransferOutCount.pending, (state, action) => {
        state.status = "getInternalTransferOutCount loading";
        state.loading = true;
      })
      .addCase(getInternalTransferOutCount.fulfilled, (state, action) => {
        state.status = "getInternalTransferOutCount successed";
        state.loading = false;
        var response = action.payload.response;
        state.internaltransferCount = response.count;
      })
      .addCase(getInternalTransferOutCount.rejected, (state, action) => {
        state.status = "getInternalTransferOutCount rejected";
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const { FilterInputChangeValue, clearData
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
