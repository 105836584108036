import { configureStore } from "@reduxjs/toolkit";
import customiseReducer from "./customise/customise";
import loginSlice from "./auth/login/loginSlice";
import miniSlice from "./mini/miniSlice";
import reportsSlice from "./Reports/reportsSlice";
import systemSlice from "./system/systemSlice";
import deleteUserSlice from "./auth/deleteAccount/deleteUserSlice";
import Masters from "./Masters/masters";
import manageUserSlice from "./settings/ManageUsers/manageUserSlice";
import manageUsersB2CSlice from "./settings/ManageUsersB2C/manageUserB2CSlice";
import manageProfileSlice from "./settings/ManageProfile/profileSlice";
import manageCompanySlice from "./settings/ManageCompany/manageCompanySlice";
import importExportSlice from "./settings/ImportExport/importExportSlice";
import appsettingsSlice from "./settings/AppSettings/appsettingsSlice";
import notificationSlice from "./notifications/notificationSlice";
// import reportsSlice from "./reports/reportsSlice";
import userProfileSlice from "./userProfile/profileSlice";
import { useSelector } from "react-redux";
import dashboardSlice from "./dashboard/dashboardSlice";
import synchronizationSlice from "./settings/Synchronization/synchronizationSlice";
import globalVariablesSlice from "./settings/globalVariables/globalVariablesSlice";
import backupDatabaseSlice from "./settings/BackupDatabse/backupDatabaseSlice";
import scheduleReportSlice from "./settings/ScheduleReports/scheduleReportSlice";
import vehicleInwardSlice from "./sideMenu/VehicleInward/vehicleInwardSlice";
import vehicleUnloadingSlice from "./sideMenu/VehicleUnloading/vehicleUnloadingSlice";
import lotTransferOutSlice from "./sideMenu/LotTransferOut/lotTransferOutSlice";
import internalTransferOutSlice from "./sideMenu/InternalTransferOut/internalTransferOutSlice";
import internalTransferInSlice from './sideMenu/InternalTransferIn/internalTransferInSlice';
import lotTransferInSlice from "./sideMenu/LotTransferIn/lotTransferInSlice";
import stockAdjustmentPlusSlice from "./sideMenu/StockAdjustmentPlus/stockAdjustmentPlusSlice";
import stockAdjustmentMinusSlice from "./sideMenu/StockAdjustmentMinus/stockAdjustmentMinusSlice";
import otherJattuActivitySlice from "./sideMenu/OtherJattuActivity/otherJattuActivitySlice";
import productionInSlice from "./sideMenu/ProductionIn/productionInSlice";
import productionOutSlice from "./sideMenu/ProductionOut/productionOutSlice";
import vehicleOutwardSlice from "./sideMenu/VehicleOutward/vehicleOutwardSlice";

export const store = configureStore({
	reducer: {
		dashboard: dashboardSlice,
		customise: customiseReducer,
		login: loginSlice,
		deleteuser: deleteUserSlice,
		mini: miniSlice,
		// reports: reportsSlice,
		system: systemSlice,
		userProfile: userProfileSlice,
		masters: Masters,
		manageUsers: manageUserSlice,
		manageUsersB2C: manageUsersB2CSlice,
		manageProfile: manageProfileSlice,
		manageCompany: manageCompanySlice,
		importExport: importExportSlice,
		appSettings: appsettingsSlice,
		notification: notificationSlice,
		sync: synchronizationSlice,
		globalVariables: globalVariablesSlice,
		backupDatabase: backupDatabaseSlice,
		scheduleReport: scheduleReportSlice,
		vehicleInward: vehicleInwardSlice,
		vehicleunloading: vehicleUnloadingSlice,
		lotTransferout: lotTransferOutSlice,
		internaltransferout: internalTransferOutSlice,
		internaltransferin: internalTransferInSlice,
		lotTransferin: lotTransferInSlice,
		stockadjustmentplus: stockAdjustmentPlusSlice,
		stockadjustmentminus: stockAdjustmentMinusSlice,
		otherjattuactivity: otherJattuActivitySlice,
		productionin: productionInSlice,
		productionout: productionOutSlice,
		vehicleoutward: vehicleOutwardSlice,
		reports: reportsSlice,
	},
});

export const Selector = (state, value) => {
	return useSelector((root) => root[state][value]);
};
export const Dispatch = (dispatch, reducer, payload) => {
	return dispatch(reducer(payload));
};
