import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space, Typography, Avatar } from "antd";
const { Text, Link } = Typography;

const ProductionInCard = (props) => {
    const { data } = props;
    return (
        <>
            <Card
                actions={[
                    <>
                        <Text type="warning" className="hp-d-block hp-caption hp-letter-space-1">
                            Milling
                        </Text>
                        <Text strong className="h6">
                             {data.millingcount ? data.millingcount : 0}
                        </Text>
                    </>,
                    <>
                        <Text type="success" className="hp-d-block hp-caption hp-letter-space-1">
                            Job Work
                        </Text>
                        <Text strong className="h6">
                            {data.jobworkcount ? data.jobworkcount : 0}
                        </Text>
                    </>,
                    <>
                        <Text className="hp-d-block hp-caption hp-text-color-primary-1">
                            Reprocessing
                        </Text>
                        <Text strong className="h6">
                              {data.reprocessingcount ? data.reprocessingcount : 0}
                        </Text>
                    </>,
                ]}
                bodyStyle={{ padding: "15px" }}
                className="hp-border-color-black-40 hp-transition hp-hover-border-color-primary-4
        hp-card-6 hp-eCommerceCardOne hp-eCommerceCardOne-wishlist hp-icon-card hp-transition"
            >
                <Row align="middle">
                    <Col
                        span={6}>
                        <Avatar size={45} icon={props.icon} className="hp-bg-primary-1 hp-text-color-black-0" />
                    </Col>
                    <Col span={16}>
                        <Text className="h6 hp-d-block" strong>
                            {props.title}
                        </Text>
                        <Text className="h4" strong>
                            {data.production_incount ? data.production_incount : 0}
                        </Text>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default ProductionInCard;
