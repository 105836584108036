import { combineReducers } from "@reduxjs/toolkit";
import Jattu from "../../view/pages/masters/jattu";
import jattuSlice from "./Jattu/jattuSlice";
import jattuActivitySlice from "./Jattu-Activity/jattu_activitySlice";
import companySlice from "./Company/companySlice";
import truckSheetSlice from "./TruckSheet/truckSheetSlice";
import stockDocumentSlice from "./StockDocument/stockDocumentSlice";
import unitSlice from "./Units/unitsSlice";
import locationsSlice from "./Locations/locationsSlice";
import worktypeSlice from "./WorkType/worktypeSlice";
import partSlice from "./Party/partySlice";
import warehouseSlice from "./Warehouse/warehouseSlice";
import lotSlice from "./Lot/lotSlice";
import itemSlice from "./Item/itemSlice";
import deliveryorderSlice from "./DeliveryOrderNumber/deliveryOrderNumberSlice";
import internaldeliveryorderSlice from "./InternalDeliveryOrder/internalDeliveryOrderSlice";
import internalShipmentSlice from "./InternalShipment/internalShipmentSlice";

const Masters = combineReducers({
    jattu: jattuSlice,
    jattuactivity: jattuActivitySlice,
    company: companySlice,
    truckSheet: truckSheetSlice,
    stockDocument: stockDocumentSlice,
    unit: unitSlice,
    locations: locationsSlice,
    worktype: worktypeSlice,
    party: partSlice,
    warehouse: warehouseSlice,
    lot: lotSlice,
    item: itemSlice,
    deliveryorder: deliveryorderSlice,
    internaldeliveryorder: internaldeliveryorderSlice,
    internalshipment : internalShipmentSlice
});

export default Masters;
